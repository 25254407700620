import * as React from "react"
import "../styles/main.scss"
import * as styles from "./styles.module.scss"
import Seo from "../components/seo"
import Layout from "../components/Layout"
import Container from "../components/Container"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ServiceScroll from "../components/ServiceScroll"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { Autoplay } from "swiper"

const IndexPage = () => {
  return (
    <Layout>
      <Container>
        <section className={styles.hero}>
          <h2>We Create What You Seek</h2>
          <h1>
            Expert Craftsmanship and Custom Designs: Elevate Your Space with
            Teca Interiors
          </h1>
          <p>
            Looking for high-quality, custom-made furniture for your home or
            office? Look no further than our interior manufacturing company! We
            at Teca Interiors specialize in designing and creating beautiful,
            functional pieces that will transform your space. From sleek modern
            designs to timeless classics, our team of expert craftsmen and
            designers can bring your vision to life. With years of experience
            and a dedication to quality, we pride ourselves on delivering
            exceptional customer service and the finest products available.
            Contact us today to learn more about how we can help you create the
            space of your dreams!
          </p>
          <Link to="/contact-us" className={styles.cta}>
            Get in touch
          </Link>
          {/* <button
            type="button"
            className={styles.heroVideo}
            href="https://youtu.be/JIQJzqGlr_E"
            data-video="https://youtu.be/JIQJzqGlr_E"
          >
            <StaticImage src="../images/Hero.JPG" alt="A dinosaur" />
          </button> */}
          <div className={styles.videoframe}>
            <iframe
              title="Teca Interiors Promo Video"
              loading="lazy"
              className={styles.video}
              name="Promo"
              src="https://www.youtube.com/embed/TijKuyWO8J0"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </section>

        <section className={styles.about}>
          <h2>About Us</h2>
          <div className={styles.aboutDetails}>
            <h1>Revolutionizing Interior Design with Teca Interiors</h1>
            <p>
              At Teca Interiors, we are passionate about designing and
              manufacturing high-quality, custom furniture that reflects the
              unique style and personality of our clients. Our team of experts
              takes great pride in delivering exceptional customer service and
              ensuring that every piece of furniture we create is of the highest
              quality.{" "}
            </p>
          </div>

          <Link to="/about" className={styles.cta}>
            Learn More
          </Link>
        </section>
      </Container>

      <StaticImage
        src="../images/interior.png"
        alt="About Image"
        className={styles.aboutImage}
      />
      <Container>
        <section className={styles.service}>
          <h2>Services</h2>
          <div className={styles.serviceDetails}>
            <h1>Discover Our Wide Range of Interior Design Services</h1>
            <p>
              Whether you are looking for a complete overhaul of your living
              space or just a few design tweaks, we've got you covered. Whether
              you're looking for a modern or classic design, we can create the
              perfect set to match your unique style. Our team will work closely
              with you to understand your unique style and preferences, and
              create a personalized solution that exceeds your expectations.
            </p>
          </div>
          <ServiceScroll />
        </section>

        <section className={styles.process}>
          <h2>Our Process</h2>
          <h1>From Concept to Creation: Our Project Journey</h1>
          <p>
            We understand that the entire process of building dream interior can
            be complex and overwhelming for some clients, so we've created a
            well-thought-out, step-by-step process to ensure that we deliver
            exactly what our clients want, on time and within budget.
          </p>
          <div className={styles.processSteps}>
            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Consultation.jpeg"
                  alt="Consultation"
                  className={styles.processImage}
                />
              </div>

              <div className={styles.processStepsCountDetail}>
                <h3>1. Consultation</h3>
                <p>
                  The first step involves a consultation with the client to
                  understand their requirements, preferences, and budget.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Design.jpeg"
                  alt="Design Brief"
                  className={styles.processImage}
                />
              </div>

              <div className={styles.processStepsCountDetail}>
                <h3>2. Design Brief</h3>
                <p>
                  Based on the consultation, Teca Interiors creates a design
                  brief that outlines the project scope, objectives, and
                  timeline.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Concept.jpeg"
                  alt="Concept Design"
                  className={styles.processImage}
                />
              </div>

              <div className={styles.processStepsCountDetail}>
                <h3>3. Concept Design</h3>
                <p>
                  The next step is to develop a concept design that reflects the
                  client's vision and incorporates Teca Interiors' expertise.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Development.jpeg"
                  alt="Design Development"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>4. Design Development</h3>
                <p>
                  Once the concept is approved, our designers create detailed
                  CAD drawings and 3D renderings that bring the design to life.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Management.jpeg"
                  alt="Project Management"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>5. Project Management</h3>
                <p>
                  Teca Interiors assigns a project manager who oversees the
                  entire project, from procurement to installation.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Sourcing.jpeg"
                  alt="Sourcing"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>6. Sourcing</h3>
                <p>
                  Our procurement team sources the finest quality materials from
                  trusted suppliers around the world to ensure that your project
                  is of the highest quality.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Manufacturing.jpeg"
                  alt="Manufacturing"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>7. Manufacturing</h3>
                <p>
                  We take pride in our in-house manufacturing process, which
                  allows us to create custom-made furniture and fixtures that
                  are unique to your project.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/installation.jpg"
                  alt="Installation"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>8. Installation</h3>
                <p>
                  Our skilled installation team ensures that the project is
                  installed to perfection, taking care of every detail to ensure
                  a flawless finish.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/quality.jpg"
                  alt="Quality Control"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>9. Quality Control</h3>
                <p>
                  We have a rigorous quality control process to ensure that
                  every aspect of your project meets our exacting standards.
                </p>
              </div>
            </div>
            <div className={styles.arrowContainer}>
              <StaticImage
                src="../images/Arrow.svg"
                alt="Arrow"
                className={styles.arrow}
              />
            </div>

            <div className={styles.processStepsCount}>
              <div className={styles.processImages}>
                <StaticImage
                  src="../images/process/Handover.JPG"
                  alt="Project Handover"
                  className={styles.processImage}
                />
              </div>
              <div className={styles.processStepsCountDetail}>
                <h3>10. Project Handover</h3>
                <p>
                  Once the project is complete, we provide handover support and
                  ongoing post-installation support as needed to ensure that you
                  are completely satisfied with the final result.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.qualities}>
          <h2>Our Qualities</h2>
          <h1>Why We're Your Best Choice</h1>
          <p>
            At Teca Interiors, we strive to provide top-quality materials,
            customization options, and unparalleled service to our customers.
            With our own manufacturing facility, we are able to offer better
            prices than our competitors while still providing a wide variety of
            options for materials and colors to choose from. Discover why Teca
            Interiors is the best choice for your interior design project.
          </p>

          <div className={styles.qualityWrapper}>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <StaticImage src="../images/icons/wood.png" alt="wood" />
              </div>
              <div className={styles.cardDetails}>
                <h1>Superior Materials</h1>
                <p>
                  At Teca Interiors, we source and use only the finest quality
                  materials for our products, ensuring that your interiors not
                  only look stunning, but are also built to last.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.cardImage}>
                <StaticImage src="../images/icons/sketch.png" alt="sketch" />
              </div>
              <div className={styles.cardDetails}>
                <h1>Personalized Design</h1>
                <p>
                  We believe that every customer is unique, and that's why we
                  offer customization options to ensure that your interior
                  design is a perfect reflection of your style and personality.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.cardImage}>
                <StaticImage src="../images/icons/luxury.png" alt="luxury" />
              </div>
              <div className={styles.cardDetails}>
                <h1>Affordable Luxury</h1>
                <p>
                  As a manufacturer, we have complete control over our
                  production costs, which means you don't have to sacrifice
                  quality for affordability when you choose Teca Interiors.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.cardImage}>
                <StaticImage src="../images/icons/infinity.png" alt="endless" />
              </div>
              <div className={styles.cardDetails}>
                <h1>Endless Possibilities</h1>
                <p>
                  We offer a wide variety of options when it comes to materials,
                  colors, and finishes. From classic to contemporary, we have
                  something for every taste and style.
                </p>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.cardImage}>
                <StaticImage src="../images/icons/service.png" alt="service" />
              </div>
              <div className={styles.cardDetails}>
                <h1>Exceptional Service</h1>
                <p>
                  From our friendly and knowledgeable staff to our timely and
                  efficient delivery, we go the extra mile to ensure that you
                  have a seamless and stress-free experience.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.factory}>
          <div className={styles.border}>
            <StaticImage
              src="../images/border.svg"
              alt="border"
              className={styles.borderImage}
            />
            <div className={styles.factoryWrapper}>
              <div className={styles.factoryImage}>
                <StaticImage src="../images/hero.png" alt="Factory" />
              </div>

              <div className={styles.factoryContent}>
                <h1>
                  Teca Interiors Manufacturing Facility - Quality Craftsmanship
                  from Our Cheruvathur Factory
                </h1>
                <p>
                  At Teca Interiors, we take pride in our advanced interior
                  manufacturing facility located in Kothankara, Cheruvathur.
                  Spanning over 10,000 square feet, our factory is equipped with
                  7 cutting-edge imported machines and staffed by a team of over
                  20+ skilled employees. This allows us to manufacture
                  high-quality interiors with precision and efficiency, ensuring
                  that our customers receive the best possible products.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.testimonials}>
          <h2>Customer Success Stories</h2>
          <h1> Inspiring Stories of Beautifully Transformed Spaces</h1>
          <p>
            {" "}
            Discover what our delighted customers have to say about their
            experiences with Teca Interiors. Read firsthand testimonials
            showcasing the exceptional craftsmanship, personalized service, and
            stunning results we consistently deliver. Get inspired by the
            stories of transformed interiors and start envisioning your own
            dream space with Teca Interiors.
          </p>

          <div className={styles.cardContainer}>
            <Swiper
              breakpoints={{
                // when window width is >= 640px
                640: {
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                },
              }}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              loop={true}
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={swiper => console.log(swiper)}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.personContainer}>
                    <StaticImage
                      src="../images/Testimonials/person.png"
                      alt="person"
                      className={styles.person}
                    />
                  </div>
                  <p className={styles.name}>Dr Prasad, Doctor</p>
                  <p className={styles.text}>
                    Working with Teca Interiors was a fantastic experience.
                    Their team guided me through the entire process and helped
                    me bring my vision to life. The attention to detail and
                    quality of their work is exceptional. I am beyond thrilled
                    with the results! I would surely recommend Teca Interiors to
                    my friends and family.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.personContainer}>
                    <StaticImage
                      src="../images/Testimonials/madhu.png"
                      alt="person"
                      className={styles.person}
                    />
                  </div>
                  <p className={styles.name}>Madhu, Business</p>
                  <p className={styles.text}>
                    I highly recommend Teca Interiors for their outstanding
                    craftsmanship and personalized service. They transformed my
                    kitchen into a functional and stylish space. The team
                    listened to my ideas and delivered beyond my expectations.
                    Thank you for creating my dream kitchen!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.personContainer}>
                    <StaticImage
                      src="../images/Testimonials/person.png"
                      alt="person"
                      className={styles.person}
                    />
                  </div>
                  <p className={styles.name}>Prashanth, FCI Employee</p>
                  <p className={styles.text}>
                    Choosing Teca Interiors was the best decision I made for my
                    home. From start to finish, they were professional,
                    reliable, and provided top-notch service. The customized
                    solutions they offered perfectly matched my style, and the
                    end result is simply stunning. I couldn't be happier!
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.personContainer}>
                    <StaticImage
                      src="../images/Testimonials/person.png"
                      alt="person"
                      className={styles.person}
                    />
                  </div>
                  <p className={styles.name}>Manoj, Contractor</p>

                  <p className={styles.text}>
                    I am extremely impressed with Teca Interiors' attention to
                    detail and their commitment to delivering high-quality
                    products. The craftsmanship of their furniture and the
                    materials they used exceeded my expectations. The team was
                    attentive and ensured that every aspect of the project was
                    flawless. I highly recommend Teca Interiors.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.card}>
                  <div className={styles.personContainer}>
                    <StaticImage
                      src="../images/Testimonials/person.png"
                      alt="person"
                      className={styles.person}
                    />
                  </div>
                  <p className={styles.name}>Lisa Roberts</p>
                  <p className={styles.text}>
                    Teca Interiors offers an incredible range of options to
                    choose from. Their extensive selection of materials and
                    colors made it easy for me to find the perfect match for my
                    home. The team was knowledgeable, friendly, and guided me
                    throughout the entire process. I am delighted with the end
                    result!
                  </p>
                </div>
              </SwiperSlide>
              ...
            </Swiper>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
