// extracted by mini-css-extract-plugin
export var about = "styles-module--about--c4148";
export var aboutDetails = "styles-module--aboutDetails--d5c57";
export var aboutImage = "styles-module--aboutImage--d8b8b";
export var arrow = "styles-module--arrow--f6efa";
export var arrowContainer = "styles-module--arrowContainer--1a815";
export var border = "styles-module--border--f5cdd";
export var borderImage = "styles-module--borderImage--bc398";
export var card = "styles-module--card--76901";
export var cardContainer = "styles-module--cardContainer--d82db";
export var cardDetails = "styles-module--cardDetails--5f9d2";
export var cardImage = "styles-module--cardImage--cbeef";
export var cta = "styles-module--cta--497fc";
export var factory = "styles-module--factory--1ef38";
export var factoryContent = "styles-module--factoryContent--6d3cf";
export var factoryImage = "styles-module--factoryImage--a93ee";
export var factoryWrapper = "styles-module--factoryWrapper--af5cc";
export var hero = "styles-module--hero--21e6a";
export var name = "styles-module--name--7b3c0";
export var person = "styles-module--person--7eb13";
export var personContainer = "styles-module--personContainer--c6b0b";
export var process = "styles-module--process--16bd4";
export var processImage = "styles-module--processImage--71902";
export var processImages = "styles-module--processImages--25917";
export var processSteps = "styles-module--processSteps--defc7";
export var processStepsCount = "styles-module--processStepsCount--49e97";
export var processStepsCountDetail = "styles-module--processStepsCountDetail--30fc1";
export var qualities = "styles-module--qualities--2fe83";
export var qualityWrapper = "styles-module--qualityWrapper--aad21";
export var service = "styles-module--service--33c71";
export var serviceDetails = "styles-module--serviceDetails--8e11e";
export var testimonials = "styles-module--testimonials--f5aa4";
export var text = "styles-module--text--15857";
export var video = "styles-module--video--ccfef";
export var videoframe = "styles-module--videoframe--67bb7";